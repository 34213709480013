import React from 'react';
import { graphql } from 'gatsby';

const Login = ({ data }: any) => {
  React.useEffect(() => {
    window.location.replace(`${process.env.GATSBY_URL_NEW_FRONT}/login`);
  }, []);

  if (typeof window !== 'undefined') {
    window.location.replace(`${process.env.GATSBY_URL_NEW_FRONT}/login`);
    return null;
  }

  return null;
};

export default Login;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
